<template>
  <div>
    <sub-header :customCrumbLists="customBredCrumbs" showOnlyCustomCrumbs />
    <UISection>
      <template #title>
        <span class="text-white-text">{{ "Registered Domains" }}</span>
      </template>
      <div class="flex flex-col gap-4">
        <div
          class="flex w-full justify-end items-center px-2"
          v-if="addAllowedDomainToggle"
        >
          <div
            class="cursor-pointer"
            @click="handleCloseAllowedDomain"
          >
            <!-- X-mark -->
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 384 512"
              class="w-4 h-4"
            >
              <path
                d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z"
              />
            </svg>
          </div>
        </div>
        <div
          class="flex items-center justify-between w-full py-2"
          v-if="!addAllowedDomainToggle"
        >
          <div class="flex items-center gap-2">
            <toggle-switch
              :status="selfRegistrationAllowed"
              @click="handleEnabledDisabled"
            ></toggle-switch>
            <span> {{ selfRegistrationAllowed ? 'Enabled' : 'Disabled' }} </span>
          </div>
          <div class="flex">
            <v-button
              text="Add Allowed Domain"
              type="primary"
              :disabled="!selfRegistrationAllowed"
              @click="handleAddAllowedDomain"
            />
          </div>
          <Loader v-if="disabled" class="h-8 w-8" :size="8"></Loader>
        </div>
        <div class="flex w-full px-2" v-if="addAllowedDomainToggle">
          <FormulateForm @submit="handleSubmit" class="w-full flex">
            <div class="flex flex-col w-full gap-4">
              <div class="flex w-full justify-between items-start gap-8">
                <div class="flex-1">
                  <!--  :validation="[
                    ['bail'],
                    ['required', 'trim'],
                    ['max', '30', 'length'],
                    ['matches', /^[\p{L}\p{N}\s]+$/u],
                  ]"
                  :validation-messages="{
                  @validation="validationName"
                  @input="handleNameInput"   
                  
                  -->
                  <FormulateInput
                    type="text"
                    v-model="formData.emailDomain"
                    label="Email Domain"
                    placeholder="Email Domain"
                    :validation="[['bail'], ['required', 'trim'], ['matches', /^(?!-)(?!.*--)([A-Za-z0-9-]{2,253}\.)+[A-Za-z0-9-]{2,253}\.[A-Za-z]{2,6}$/]]"
                    :validation-messages="{
                      matches: 'Email domain not valid'
                    }"
                    :element-class="
                      (context, classes) =>
                        ['flex-1 min-w-full'].concat(classes)
                    "
                    error-behavior="blur"
                  >
                  </FormulateInput>
                </div>
                <div class="flex-1 items-center">
                  <div class="items-center">
                    <span class="label items-center capitalize max-w-full"
                      >Authentication method <span class="text-red-500 ml-1 text-lg leading-3 mr-auto">*</span></span
                    >
                    <v-dropdown
                      customHeight
                      reposition
                      searchable
                      :multiple="true"
                      tag-placeholder="Status"
                      :close-on-select="true"
                      placeholder="Authentication Method"
                      :show-labels="false"
                      id="authenticationMethods"
                      class="dropdownRoles"
                      :options="authenticationMethodsList"
                      ref="status"
                      v-model="formData.authenticationMethods"
                      :loading="false"
                      :colors="{
                        text: '#282828',
                        bg: 'white',
                        svg: '#282828',
                        border: '#e8e8e8',
                      }"
                      :value="formData.authenticationMethods"
                      :config="{ label: 'name', trackBy: 'id', limit: 1 }"
                      minWidth="100%"
                      maxWidth="80%"
                      width="100px"
                    >
                    </v-dropdown>
                  </div>
                </div>
                <div class="flex">
                  <div class="mt-9">
                    <div class="flex items-center gap-5">
                      <!--  @change="handleIsActive"
                      :disabled="status.is_default" -->
                      <input
                        type="checkbox"
                        class="w-5 h-5 cursor-pointer"
                        v-model="formData.approvalRequired"
                      />
                      <label>Approval required</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex w-full justify-between items-start gap-5">
                <div class="flex-1">
                  <div class="items-center">
                    <span class="label items-center capitalize max-w-full"
                      >Default Roles <span class="text-red-500 ml-1 text-lg leading-3 mr-auto">*</span></span
                    >
                    <v-dropdown
                      reposition
                      searchable
                      :multiple="true"
                      tag-placeholder="Status"
                      :close-on-select="true"
                      placeholder="Default Roles"
                      :show-labels="true"
                      label="hello"
                      id="defaultRoles"
                      class="dropdownRoles"
                      :options="roles"
                      ref="status"
                      v-model="formData.defaultRoles"
                      :loading="false"
                      :colors="{
                        text: '#282828',
                        bg: 'white',
                        svg: '#282828',
                        border: '#e8e8e8',
                      }"
                      :value="formData.defaultRoles"
                      :config="{ label: 'name', trackBy: 'id' }"
                      minWidth="100%"
                      maxWidth="80%"
                      width="100px"
                    >
                    </v-dropdown>
                  </div>
                </div>
                <div class="flex-1 items-center">
                  <div class="items-center">
                    <span class="label items-center capitalize max-w-full"
                      >Default Groups <span class="text-red-500 ml-1 text-lg leading-3 mr-auto">*</span></span
                    >
                    <v-dropdown
                      reposition
                      searchable
                      :multiple="true"
                      tag-placeholder="Status"
                      :close-on-select="true"
                      placeholder="Default Groups"
                      :show-labels="true"
                      label="hello"
                      id="defaultGroups"
                      class="dropdownRoles"
                      :options="groups"
                      ref="status"
                      v-model="formData.defaultGroups"
                      :loading="false"
                      :colors="{
                        text: '#282828',
                        bg: 'white',
                        svg: '#282828',
                        border: '#e8e8e8',
                      }"
                      :value="formData.defaultGroups"
                      :config="{ label: 'name', trackBy: 'id' }"
                      minWidth="100%"
                      maxWidth="80%"
                      width="100px"
                    >
                    </v-dropdown>
                  </div>
                </div>
                <div class="flex-1 items-center">
                  <FormulateInput
                    type="number"
                    min="1"
                    v-model="formData.selfRegistrationValid"
                    validation="required|number"
                    label="Self Registration Valid (in Days)"
                    placeholder="Self Registration Valid"
                    :element-class="
                      (context, classes) =>
                        ['flex-1 min-w-full'].concat(classes)
                    "
                    error-behavior="value"
                  >
                  </FormulateInput>
                </div>
                <div class="flex gap-2 items-center">
                  <div class="mt-8">
                    <v-button
                      text="save"
                      type="primary"
                      :loader="loadingState.submit"
                      :disabled="!disabledSubmit"
                      @click="handleSubmit"
                    />
                  </div>
                </div>
              </div>
            </div>
          </FormulateForm>
        </div>
        <!-- Table -->
        <div class="w-full scroll-bar" style="max-height: 72vh;">
          <div
            class="flex relative flex-1 flex-col flex-grow max-h-full max-w-full"
          >
            <!-- @on-sort-change="onSortChange" -->
            <!-- :sort-options="{
              enabled: true,
            }" -->
            <!--  @on-page-change="onPageChange($event)"
            @on-per-page-change="onPerPageChange($event)" -->
            <vue-good-table
              id="pendingApprovalList"
              :rows="getRows"
              :columns="columns"
              :isLoading="loadingState.registeredDomain"
              :pagination-options="{
                enabled: true,
                mode: 'records',
                dropdownAllowAll: false,
                perPage: perPage,
              }"
              :key="pageData?.total"
            >
              <!-- <template #column-filter="{ column }">
              <Dropdown
                reposition
                searchable
                :multiple="column.filterOptions.multi || false"
                class="filter-dropdown"
                :placeholder="$t('general.dropdown_placeholder')"
                v-if="column.filterOptions && column.filterOptions.enabled"
                :options="column.filterOptions.filterDropdownItems"
                :value="getSelectedOptions(column)"
                @input="(value) => updateColumnFilters(column, value)"
                @search="searchFilter(column, $event)"
                :limit="1"
                maxWidth="100%"
                width="100px"
                minWidth="100%"
                :config="checkColumnConfig(column)"
                :loading="column.filterOptions.isLoading"
                :taggable="false"
                :preserve-search="true"
                :close-on-select="false"
                :clear-on-select="false"
                :showCheckBox="column.filterOptions.multi || false"
                :selectedOptions="selectedOptions[column.label]"
                :isTableLargeDropdown="true"
              >
              </Dropdown>
            </template> -->
              <template slot="table-row" slot-scope="props">
                
                <div
                  v-if="props.column.field === 'authentication_methods'"
                  class="flex gap-3"
                >
                  <div class="flex gap-2">
                    <template>
                      <div
                        v-for="item in props.row.authentication_methods"
                        :key="item.id"
                        class="bg-blue-100 text-primary text-sm shadow truncate px-1 rounded font-normal"
                        :title="item.name"
                      >
                        {{ item.name }}
                      </div>
                    </template>
                  </div>
                </div>
                <div
                  v-else-if="props.column.field === 'approval_required'"
                  class="flex"
                >
                  <div class="flex items-center justify-center px-4 w-full" :class="{ 'opacity-50' : !selfRegistrationAllowed}">
                    <template>
                      <input
                        type="checkbox"
                        class="w-5 h-5 pointer-events-none cursor-default"
                        :checked="props.row.approval_required"
                      />
                    </template>
                  </div>
                </div>
                <div
                  v-else-if="props.column.field === 'default_roles'"
                  class="flex gap-3"
                >
                  <div class="flex gap-2">
                    <template>
                      <div
                        v-for="role in props.row.default_roles"
                        :key="role.id"
                        class="bg-blue-100 text-primary text-sm shadow truncate px-1 rounded font-normal"
                        :title="role.name"
                      >
                        {{ role.name }}
                      </div>
                    </template>
                  </div>
                </div>
                <div
                  v-else-if="props.column.field === 'default_groups'"
                  class="flex gap-3"
                >
                  <div class="flex gap-2">
                    <template>
                      <div
                        v-for="group in props.row.default_groups"
                        :key="group.id"
                        class="bg-blue-100 text-primary text-sm shadow truncate px-1 rounded font-normal"
                        :title="group.name"
                      >
                        {{ group.name }}
                      </div>
                    </template>
                  </div>
                </div>
                <div
                  v-else-if="props.column.field == 'action'"
                  class="flex justify-center cursor-pointer"
                >
                  <div class="flex items-center justify-between gap-4">
                    <button class="cursor-pointer" :class="{'opacity-50': disabledAction(props.row.id)}" @click="handleEdit(props.row)" :disabled="disabledAction(props.row.id)">
                      <font-awesome-icon
                        icon="edit"
                        class="text-blue-600 text-lg fa-3x"
                      />
                    </button>
                    <button class="cursor-pointer" :class="{'opacity-50':  disabledAction(props.row.id)}" @click="handleDelete(props.row)" :disabled="disabledAction(props.row.id)">
                      <font-awesome-icon
                        icon="trash"
                        class="text-red-600 text-lg fa-3x"
                      />
                    </button>
                  </div>
                </div>
                <div v-else>
                    {{ props.row[props.column.field] }}
                </div>
              </template>

              <template #loadingContent>
                <div class="w-full py-10 justify-center flex">
                  <Loader />
                </div>
              </template>
              <template #emptystate>
                <div
                  class="alert alert-info py-5 my-5"
                  v-if="
                    !loadingState.registeredDomain && !pendingApprovalList.length
                  "
                >
                  No Data Found
                </div>
                <div v-else class="py-16"></div>
              </template>
            </vue-good-table>
            <!-- <button
            v-if="
              selectedDropDownItems && Object.keys(selectedDropDownItems).length
            "
            class=" cursor-pointer py-1 px-3 bg-gray-100 hover:bg-gray-200 rounded-md text-xs text-black flex justify-center items-center font-semibold absolute left-2 top-2 z-50"
            @click="clearFilters"
          >
            Clear filters
          </button> -->
          </div>
        </div>
      </div>
    </UISection>

    <AddUserModal
      :user="userData"
      ref="AddUserModal"
      @handleSubmit="addUser($event)"
      :roles="roles"
      :groups="groups"
      :loadingState="loadingState"
      rolesLabel="Roles"
      groupLabel="Groups"
      submitButtonText="Approved"
    />

    <ModalConfirm title="Are you sure?" message="" ref="confirm-popup">
    </ModalConfirm>
  </div>
</template>

<script>
// import axios from "@/axios";
import SubHeader from "@/components/SubHeader";
import axios from "@/axios";
import Loader from "@shared/loader";
// import uniqBy from "lodash";
import AddUserModal from "@shared/components/addUserModal";
// import Checkbox from "@/components/checkbox";
import ToggleSwitch from "@shared/components/toggle-switch";
import Button from "@shared/components/button";
import Dropdown from "@shared/components/dropdown-base";
const ModalConfirm = () => import("@/components/modal-confirm");
import { mapGetters } from "vuex"
import { updateClientInfo, fetchClientInfo } from "../services";



// import Loader from "@shared/loader";

export default {
  name: "RegisteredDomains",
  props: {
    organizationType: {
      type: String,
      default: "CLIENT_INTERNAL"
    },
    clientDetails: {
      type: Object,
      default: ()=>{}
    }
  },
  components: {
    SubHeader,
    Loader,
    AddUserModal,
    ModalConfirm,
    // Checkbox,
    ToggleSwitch,
    "v-button": Button,
    "v-dropdown": Dropdown,
    // Loader
  },
  data() {
    return {
      selfRegistrationAllowed: false,
      disabled: false,
      columns: [
        {
          label: "Allowed Email Domain",
          field: "domain",
          filterOptions: {
            enabled: false, // enable filter for this column
            multi: true,
            isLoading: false,
            query_key: "domain",
          },
        },
        {
          label: "Authentication method",
          field: "authentication_methods",
          filterOptions: {
            enabled: false, // enable filter for this column
            multi: true,
            isLoading: false,
          },
        },
        {
          label: "Approval Required",
          field: "approval_required",
          filterOptions: {
            enabled: false, // disable filter for this column
          },
        },
        {
          label: "Default Role",
          field: "default_roles",
          sortable: false,
          width: "100px",
          filterOptions: {
            enabled: false, // enable filter for this column
            multi: true,
            isLoading: false,
            query_key: "role",
          },
        },
        {
          label: "Default Group",
          field: "default_groups",
          sortable: true,
          filterOptions: {
            enabled: false, // enable filter for this column
            multi: true,
            isLoading: false,
            query_key: "group",
          },
        },
        {
          label: "Registration Validity",
          field: "registration_validity",
          filterOptions: {
            enabled: false, // enable filter for this column
            multi: true,
            isLoading: false,
            query_key: "username",
          },
        },
        {
          label: "Action",
          field: "action",
          filterOptions: {
            enabled: false, // enable filter for this column
            multi: true,
            isLoading: false,
            query_key: "username",
          },
        },
      ],
      searchParams: null,
      pendingApprovalList: [],

      loadingState: {
        registeredDomain: false,
        roles: false,
        groups: false,
        submit: false,
      },
      pageData: null,
      perPage: 10,
      roles: [],
      groups: [],
      userData: {
        username: "",
        email: "",
        roles: [],
        groups: [],
        mobile: "",
        position: "",
      },
      checked: false,
      addAllowedDomainToggle: false,
      formData: {
        emailDomain: "",
        authenticationMethods: [],
        approvalRequired: true,
        defaultRoles: [],
        defaultGroups: [],
        selfRegistrationValid: "",
      },
      authenticationMethodsList: [],
      defaultRolesList: [],
      defaultGroupList: [],
      clientId: "",
      isEditTheInfo: false,
      selectedRowId: "",
    };
  },
  async mounted() {
    this.clientId = this.$route.params.clientId;
    console.log("getTenantId", this.getTenantId);
    await this.fetchSelfRegAllowed();
    await this.fetchRegisteredDomainList();
    await this.fetchOrganizationMethodList();
    await this.fetchRoles();
    await this.fetchGroups();
    
  },
  computed: {
    ...mapGetters(["getTenantId"]),
    customBredCrumbs() {
      return [{ name: "Admin" }, { name: "Pending Approval" }];
    },
    getRows() {
      console.log("pendingApprovalList", this.pendingApprovalList)
      return this.pendingApprovalList;
    },
    getConfig() {
      return this.organizationType === 'TENANT' ? {name: 'tenant', id: this.getTenantId }:  {name: 'client', id: this.clientId};
    },
    disabledSubmit() {
     return (this.formData.emailDomain && this.formData.authenticationMethods.length && this.formData.defaultRoles.length && this.formData.defaultGroups.length && this.formData.selfRegistrationValid ? true : false)
    },
  },
  methods: {
    async fetchSelfRegAllowed() {
      const { self_registration_allowed } = await fetchClientInfo(this.clientId)
      this.selfRegistrationAllowed = self_registration_allowed;
    },
    disabledAction(id) {
      if(!this.selfRegistrationAllowed) {
        return true;
      } else if(this.addAllowedDomainToggle) {
        if(this.isEditTheInfo) {
          return this.selectedRowId === id ? false : true
        } else {
          return true;
        }
      }
    },
    handleCloseAllowedDomain(){
      this.addAllowedDomainToggle = false;
      this.isEditTheInfo = false;
      this.resetAllInputsFields();
    },
    resetAllInputsFields() {
      this.formData = {
        emailDomain: "",
        authenticationMethods: [],
        approvalRequired: false,
        defaultRoles: [],
        defaultGroups: [],
        selfRegistrationValid: "",
      }
    },
    async fetchRoles() {
      // this.loadingState.roles = true;
      try {
        const { name, id } = this.getConfig;
        let { data } = await axios.get(`role/${name}/${id}`);
        this.roles = data.map((item) => ({...item, name: item.title}));
      } catch (error) {
        console.log(error, ">>>>error");
      }
      // this.loadingState.roles = false;
    },
    async fetchGroups() {
      // this.loadingState.groups = true;
      try {
        const { name, id } = this.getConfig;
        this.organizationType 
        let { data } = await axios.get(`group/${name}/${id}`);
        this.groups =  data.map((item) => ({...item, name: item.title}));
      } catch (error) {
        console.log(error, ">>>>error");
      }
      // this.loadingState.groups = false;
    },
    async fetchOrganizationMethodList() {
    
      try {
        const { id } = this.getConfig;
        const url = `self-registration/authentication_method?organization_id=${id}&organization_type=${this.organizationType}`;
        const response  = await axios.get(url);
        this.authenticationMethodsList = response?.data;
      } catch (err) {
        console.log("err", err)
      }

    },
    async handleSubmit() {
      if (!this.formData.emailDomain) {
        this.$toast.error("Please enter the email domain.");
        return;
      }
      if (!this.formData.authenticationMethods.length) {
        this.$toast.error("Please select the authentication method.");
        return;
      }
      if (!this.formData.selfRegistrationValid) {
        this.$toast.error("Please enter the self registration valid (in days).");
        return;
      }
      try {
        this.loadingState.submit = true;
        let payload = {
          organization_type: this.organizationType,
          domain: this.formData.emailDomain.trim(),
          approval_required: this.formData.approvalRequired,
          registration_validity: this.formData.selfRegistrationValid,
          authentication_methods: this.formData.authenticationMethods.map((el)=> el.id),
          default_roles: this.formData.defaultRoles.map((el)=> el.id),
          default_groups: this.formData.defaultGroups.map((el)=> el.id),
          tenant_id: this.getTenantId,
          client_id: this.clientId,
        }

        const url = this.isEditTheInfo ? `self-registration/organization_email_domain/${this.selectedRowId}` : `self-registration/organization_email_domain`;
       
        const response =  this.isEditTheInfo ? await axios.put(url, payload): await axios.post(url, payload);
        console.log("res", response);
        if(response?.data?.status) {
          this.$toast.success(response?.data?.detail || "Submit");
          this.handleCloseAllowedDomain();
          this.fetchRegisteredDomainList();
        }
        this.loadingState.submit = false;
        } catch (err) {
        console.error("err", err);
        this.$toast.error(err.response?.data?.detail || "Something went wrong.")
        this.loadingState.submit = false;
      }
    },
    handleAddAllowedDomain() {
      this.addAllowedDomainToggle = true;
      this.isEditTheInfo = false;
    },
   

    async handleDelete(row) {
      const promise = await this.$refs["confirm-popup"].show({
        title: "Are you sure?",
        message:
          "This self registration will be deleted permanently, please confirm",
        buttonText: "Confirm",
      });

      if (promise) {
        try {
            let { data } = await axios.delete(`self-registration/organization_email_domain/${row.id}`);
            this.$toast.success(data?.detail || "Deleted.");
            this.fetchRegisteredDomainList();
        } catch (error) {
            this.$toast.error(error.response.data.detail || "Failed to delete");
            console.log("error", error);
        }
      }
      this.$refs["confirm-popup"].close();
    },
    handleEdit(row) {
      this.selectedRowId = row.id;
      this.isEditTheInfo = true;
      this.addAllowedDomainToggle = true;
      this.resetAllInputsFields();
      this.fillDataInFields(row);
    
      // console.log("ddd", this.$refs["AddUserModal"]);
      // this.$refs["AddUserModal"].showUserModal();
    },
    fillDataInFields(row) {
      console.log("row", row);
      this.formData.emailDomain = row?.domain;
      this.formData.approvalRequired = row?.approval_required;
      this.formData.selfRegistrationValid = row?.registration_validity;
      this.formData.authenticationMethods = row?.authentication_methods;
      this.formData.defaultRoles = row?.default_roles;
      this.formData.defaultGroups = row?.default_groups;
    },
    addUser(userData) {
      console.log("userData", userData);
    },
    async fetchRegisteredDomainList() {
      this.loadingState.registeredDomain = true;
      const { id } = this.getConfig;
      let url = `self-registration/organization_email_domain?organization_id=${id}&organization_type=${this.organizationType}`;
      try {
        let { data } = await axios.get(url);
        this.pendingApprovalList = data;
      } catch (error) {
        this.$toast.error(
          error?.response?.data?.detail || "Something went wrong"
        );
      }
      this.loadingState.registeredDomain = false;
    },
    async handleEnabledDisabled(){
      this.selfRegistrationAllowed = !this.selfRegistrationAllowed;
      let payload= {
        "self_registration_allowed": this.selfRegistrationAllowed
      }
      const response = await updateClientInfo(this.clientId, payload);
      if(response?.success) {
        this.fetchSelfRegAllowed();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@shared/assets/mixins/mixins.scss";
@include tableComponent;

.filter-dropdown {
  @apply border-transparent bg-white;
}

.formulate-input[data-classification="button"] button {
  background-color: var(--theme-color-primary-300);
  border: 0;
  border-radius: 10px;
}

.formulate-input[data-classification="button"] button:active {
  border: 0;
}

.formulate-input[data-classification="button"] button[disabled]:hover {
  border: 0;
  border-radius: 10px;
}

.formulate-input[data-classification="text"] input[disabled] {
  pointer-events: none;
  opacity: 0.5;
  /* Optional: Add additional styles to convey disabled state */
  background-color: #f2f2f2;
  color: #999;
  cursor: not-allowed;
}
</style>
