<!-- Localized -->
<template>
    <ModalContent name="group-modal" ref="modal" width="640px" v-bind="$attrs" v-on="$listeners" submitButtonText="Add">
        <template #title>{{ $t('pages.group_details.create_group') }}</template>
        <template #content>
            <div class="flex gap-6 flex-col h-full">
                <FormulateForm class="min-h-full flex-1">
                    <FormulateInput class="w-full" :label="$t('pages.group_details.group_name')" :placeholder="$t('pages.group_details.group_name')" type="text" validation="required" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" v-model="groupnameModel" />
                    <FormulateInput class="w-full" :label="$t('pages.group_details.group_description')" :placeholder="$t('pages.group_details.group_description')" type="textarea" validation="required" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" v-model="groupdescriptionModel" />
                </FormulateForm>
                <h3 style="color: gray; opacity: 0.75">{{ $t('pages.group_details.more_settings_available_ongroup_created') }}</h3>
            </div>
        </template>
    </ModalContent>
</template>

<script>
import ModalContent from "@shared/modal-content";
export default {
    name: "create-group-modal",
    components: {
        ModalContent,
    },
    props: {
        groupname: {
            type: String,
            default: "",
        },
        groupdescription: {
            type: String,
            default: "",
        },
    },
    data() {
        return {};
    },
    computed: {
        groupnameModel: {
            get() {
                return this.groupname;
            },
            set(val) {
                this.$emit("update:groupname", val);
            },
        },
        groupdescriptionModel: {
            get() {
                return this.groupdescription;
            },
            set(val) {
                this.$emit("update:groupdescription", val);
            },
        },
    },
    // mounted(){
    //     this.closeModal();
    // },
    methods: {
        closeModal() {
            this.$modal.hide("group-modal");
        },
        submitForm() {
            this.$emit("submit", this.closeModal);
        },
    },
};
</script>

<style lang="scss" scoped></style>
