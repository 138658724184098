<!-- Localized -->
<template>
    <div>
        <div class="bg-card-bg p-6 rounded-lg py-24 flex items-center justify-center" v-if="loadingState.client">
            <Loader />
        </div>
        <FormulateForm #default="{ hasErrors }" v-else>
            <div class="flex flex-col gap-6">
                <!-- Financial Details -->
                <div>
                    <h1 class="text-gray-700 font-semibold text-lg mb-3">{{ $t('pages.tenant_client.financial_details') }}</h1>
                    <div class="bg-card-bg p-6 rounded-lg">
                        <div class="grid grid-cols-2 gap-6">
                            <FormulateInput :disabled="!disableAllField" class="w-full" :label="$t('pages.tenant_client.tax_number_eg_vat_gstin_etc')" :placeholder="$t('pages.tenant_client.tax_number_eg_vat_gstin_etc')" type="text" v-model="financials.financial_details.tax_number" @blur="spaceTrim" @input="onInput" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                            <div>
                                <h2 class="mb-2 mt-1">{{ $t('pages.tenant_client.currency') }}</h2>
                                <Dropdown
                                    disabled
                                    :placeholder="$t('pages.tenant_client.currency')"
                                    :options="currencyOptions"
                                    searchable
                                    :colors="{
                                        text: '#282828',
                                        bg: 'white',
                                        svg: '#282828',
                                        border: '#bcbcbc',
                                    }"
                                    :value="financials.financial_details.currency"
                                    @input="(value) => updateCurrency(value)"
                                    :config="{ label: 'code', trackBy: 'code' }"
                                    maxWidth="100%"
                                    minWidth="100%"
                                    width="102px"
                                    :loading="loadingState.currency"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Purchase Details -->
                <!-- <div>
                    <h1 class="text-gray-700 font-semibold text-lg mb-3">Billing Address</h1>
                    <div class="bg- p-6 rounded-lg">
                        <div class="grid grid-cols-2 gap-6">
                            <FormulateInput class="w-full" label="Purchase Account" placeholder="Purchase account" type="text" v-model="financials.purchase_details.purchase_account" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                            <FormulateInput class="w-full" label="Billing due date" placeholder="Billing due date" type="date" v-model="financials.purchase_details.billing_due_date" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                        </div>
                        <div class="grid grid-cols-2 gap-6 mb-6">
                            <div>
                                <h2 class="mb-2 mt-1">Currency</h2>
                                <Dropdown
                                    placeholder="Amounts are"
                                    :options="amountsAreOptions"
                                    :colors="{
                                        text: '#282828',
                                        bg: 'white',
                                        svg: '#282828',
                                        border: '#bcbcbc',
                                    }"
                                    v-model="financials.purchase_details.amounts_are"
                                    maxWidth="100%"
                                    minWidth="100%"
                                    width="102px"
                                />
                            </div>

                            <div>
                                <h2 class="mb-2 mt-1">Currency</h2>
                                <Dropdown
                                    placeholder="purchase VAT"
                                    :options="purchaseVatOptions"
                                    :colors="{
                                        text: '#282828',
                                        bg: 'white',
                                        svg: '#282828',
                                        border: '#bcbcbc',
                                    }"
                                    v-model="financials.purchase_details.purchase_vat"
                                    maxWidth="100%"
                                    minWidth="100%"
                                    width="102px"
                                />
                            </div>
                        </div>
                        <div class="grid grid-cols-2 gap-6">
                            <FormulateInput class="w-full" label="Type" placeholder="Type" type="text" v-model="financials.purchase_details.type" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                            <FormulateInput class="w-full" label="Salesman" placeholder="Salesman" type="text" v-model="financials.purchase_details.salesman" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                        </div>
                    </div>
                </div> -->
            </div>
            <div class="flex my-4  w-full justify-between" v-if="disableAllField" >
                <Button :text="$t('actions.back')" type="outline" @click="$emit('handleBackSection')" />
                <Button :text="$t('pages.tenant_client.save__next')" :loader="isFormSubmit" type="primary" @click="handleSubmit(hasErrors)" />
            </div>
        </FormulateForm>
    </div>
</template>

<script>
import Button from "@shared/components/button";
import Dropdown from "@shared/components/dropdown-base";
import { fetchClientInfo, getCurrencyList, fetchTenantDetails, fetchTenantCurrencyDetails } from "../services";
import Loader from "@shared/loader";
import { checkPermission } from "@shared/utils/functions"

export default {
    name: "client-financial",
    components: {
        Button,
        Dropdown,
        Loader,
    },
    props: {
        loadingStates:{
            type:Object,
            default: () => {}
        },
        isClientOnboarded:{
            type:Boolean,
            default: false
        }
    },
    data() {
        return {
            financials: {
                financial_details: {
                    tax_number: "",
                    currency: "",
                },
                // purchase_details: {
                //     purchase_account: "",
                //     billing_due_date: "",
                //     amounts_are: "",
                //     purchase_vat: "",
                //     type: "",
                //     salesman: "",
                // },
            },
            currencyOptions: [],
            amountsAreOptions: ["Tax Inclusive", "Tax Exclusive", "No Tax"],
            purchaseVatOptions: ["12.5% (VAT on expenses)", "20% (VAT on expenses)", "20% (VAT on income)", "EC acquisitions (205)"],
            loadingState: {
                currency: false,
                client: false,
            },
            clientId: null,
        };
    },
    computed:{
        isFormSubmit(){
            return this.loadingStates.formSubmit
        },
        // disableAllField(){
        //     return !this.checkPermission('client.edit') || false
        // }
        disableAllField(){
            // if(this.$route.name === 'create-client' && !this.isClientOnboarded)  return this.checkPermission('client.create')
            if(this.$route.name === 'client-details' && !this.isClientOnboarded) return this.checkPermission('client.create') 
            else if(this.$route.name === 'client-details' && this.isClientOnboarded) return this.checkPermission('client.edit')
            else return false
        }
    },
    async mounted() {
        this.loadingState.client = true;
        await this.getCurrencyLists();
        await this.getClientDetails();
    },
    methods: {
        checkPermission,
        async getCurrencyLists() {
            this.loadingState.currency = true;
            this.currencyOptions = await getCurrencyList();
            this.loadingState.currency = false;
        },
        spaceTrim({target}) {
            const trimValue = target.value
            this.financials.financial_details.tax_number = trimValue.trim()
        },
        onInput(event){
            const sanitizedValue = event.replace(/[^0-9 -]/g, '') ;
            this.financials.financial_details.tax_number = sanitizedValue;
        },
        updateCurrency(value) {
            this.financials.financial_details.currency = value.code;
        },
        async getClientDetails() {
            try {
                this.loadingState.client = true;
                let tenantId = this.$store.getters.getTenantId
                let tenantDetails  = await fetchTenantDetails(tenantId);
                let tenantCurrency = await fetchTenantCurrencyDetails(tenantDetails?.currency_id)
                this.clientId = this.$route.params.clientId;
                if (this.clientId) {
                    let response = await fetchClientInfo(this.clientId);
                    let { tax_number } = response;
                    this.financials = { ...this.financials, financial_details: { tax_number, currency: tenantCurrency.currency_code } };
                }
            } catch (error) {
                if (Array.isArray(error?.response?.data?.detail)) {
                    this.$toast.error(error?.response?.data?.detail?.[0]?.msg);
                } else {
                    this.$toast.error(error?.response?.data?.detail);
                }
            }
            this.loadingState.client = false;
        },
        handleSubmit(hasErrors) {
            if (hasErrors) {
                this.$toast.error(this.$t('pages.tenant_client.please_fill_all_the_required_fields'));
            } else {
                let payload = {
                    data: {...this.financials.financial_details},
                    onBoard: false
                }

                this.$emit("submitForm", payload);
            }
        },
    },
};
</script>

<style lang="scss" scoped></style>
