<!-- Localized -->
<template>
    <!-- style="height: calc(100% + 32px); width: calc(100% + 10px)" -->
    <div
      class=""
    >
      <Tabs @select="selectTab" :selected="tabs.selected" :tabs="tabs.list">
        <template #content-userList>
          <user-list />
        </template>
        <template #content-pendingApprovals>
          <pending-approvals />
        </template>
      </Tabs>
    </div>
  </template>
  
  <script>
  import Tabs from "@/components/dashboard-components/tabs";
  import UserList from "./UserList.vue";
  import PendingApprovals from "./PendingApprovals.vue";
  import axios from "@/axios";
  import { EventBus } from "@/main.js";

  
  export default {
    name: "UserDetail",
    components: {
      Tabs,
      UserList,
      PendingApprovals,
    },
    data() {
      return {
        tabs: {
          selected: "userList",
          list: [
            {
              id: "userList",
              label: "User List",
            },
            {
              id: "pendingApprovals",
              label: "Pending Approval",
              showCount: true,
              count: 0,
            },
          ],
        },
        clientId: null,
      };
    },
    mounted() {
      this.clientId = this.$route.params.clientId;
      this.tabs.selected = this.$route?.query?.tab || "userList";
      this.fetchPendingApprovalList();
      EventBus.$on("updateCountForPendingApproval", (pendingApprovalCount) => {
        this.tabs.list.forEach((el) => {
            if(el.id === 'pendingApprovals') {
              el.count = pendingApprovalCount;
            }
          })
      })
    },
    methods: {
      selectTab(id) {
        this.tabs.selected = id;
        this.$router.push({
          path: this.$route.path,
          query: {
            tab: this.tabs.selected,
          },
        });
      },
      async fetchPendingApprovalList() {
        let url = `user/client/${this.clientId}/pending`;
        try {
          const response = await axios.get(url);
          let pendingApprovalCount = response.data.filter((el) => el.status === 'PENDING').length;
          this.tabs.list.forEach((el) => {
            if(el.id === 'pendingApprovals') {
              el.count = pendingApprovalCount;
            }
          })
        } catch (error) {
          console.log("err", error);
        }
    },
    },
  };
  </script>
  
  <style lang="scss" scoped></style>
  