<!-- Localized -->
<template>
  <div class="flex w-full flex-col gap-4 h-full">
    <SectionHeader
      :back="Boolean(selectedRole)"
      @back="backToRoles"
    >
      <Button
        v-if="checkPermission('role.create')"
        type="primary"
        :text="$t('pages.tenant_client.create_new_role')"
        @click="handleShowModal"
      />
    </SectionHeader>

    <RoleDetailsContainer
      v-if="selectedRole"
      :role-id="selectedRole"
      :admin-panel="adminList.CLIENT"
      :client-id="clientId"
    />

    <RolesAndPermissionsContainer
      v-else
      :admin-panel="adminList.CLIENT"
      :client-id="clientId"
      :showAddRoleModal="showAddRoleModal"
      @closeAddRoleModal="handleCloseModal"
      @onRoleEditClick="handleRoleEditClick"
    />
  </div>
</template>

<script>
import Button from "@shared/components/button";
import { SectionHeader } from "../components/UI";
import {
  RolesAndPermissionsContainer,
  RoleDetailsContainer
} from "@shared/components/roles-and-permissions";
import { checkPermission } from "@shared/utils/functions";
import { adminList } from "@shared/components/roles-and-permissions/utils/constants";

export default {
  name: "RolesAndPermissions",

  components: {
    Button,
    RolesAndPermissionsContainer,
    RoleDetailsContainer,
    SectionHeader,
  },

  data() {
    return {
      showAddRoleModal: false,
      selectedRole: null,
    }
  },

  created() {
    const { id } = this.$route.query;
    this.selectedRole = id ?? null;
  },

  computed: {
    clientId() {
      return this.$route.params.clientId;
    },
    adminList() {
      return adminList;
    }
  },

  methods: {
    checkPermission,

    async handleShowModal() {
      this.showAddRoleModal = true;
    },

    handleCloseModal() {
      this.showAddRoleModal = false;
    },
    
    handleRoleEditClick(roleId) {
      this.selectedRole = roleId;
      this.$router.push({ query: { id: this.selectedRole } });
    },

    backToRoles() {
      this.selectedRole = null;
      this.$router.push({ query: {} });
    },
  },
}
</script>
