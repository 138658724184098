<!-- Localized -->
<template>
    <div>
        <div class="bg-card-bg p-6 rounded-lg py-24 flex items-center justify-center" v-if="loadingState.fetching">
            <Loader />
        </div>
        <FormulateForm #default="{ hasErrors }" v-else>
            <div class="flex flex-col gap-6">
                <!-- Primary Contact -->
                <div>
                    <h1 class="text-base-content-600 font-semibold text-lg mb-3">{{ $t('pages.tenant_client.primary_contact') }}</h1>
                    <div class="bg-card-bg p-6 rounded-lg">
                        <div class="grid grid-cols-2 gap-6">
                            <FormulateInput :disabled="!disableAllField" class="w-full" :label="$t('pages.tenant_client.name')" :placeholder="$t('pages.tenant_client.name')" type="text" validation="required|matches:/^[a-zA-Z\s]+$/" :validation-messages="{ matches: $t('pages.tenant_client.rule_name_not_only_numbers_spec_symb')}" v-model="contact.primary_contact.name" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" /> 
                            <FormulateInput :disabled="!disableAllField" class="w-full" :label="$t('pages.tenant_client.position')" :placeholder="$t('pages.tenant_client.position')" type="text" validation="required|matches:/^[a-zA-Z\s]+$/" :validation-messages="{ matches: $t('pages.tenant_client.rule_position_not_only_numbers_spec_symb')}" v-model="contact.primary_contact.position" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                        </div>
                        <div class="grid grid-cols-2 gap-6">
                            <FormulateInput :disabled="!disableAllField" class="w-full" :label="$t('pages.tenant_client.phone')" validation="required" :placeholder="$t('pages.tenant_client.phone')" type="text" v-model="contact.primary_contact.phone" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" @keypress="isIntegerNumber($event)" />
                            <FormulateInput :disabled="!disableAllField" class="w-full" :label="$t('pages.tenant_client.email')" validation="required|email" :placeholder="$t('pages.tenant_client.email')" type="email" v-model="contact.primary_contact.email" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                        </div>
                        <div class="flex 2xl:justify-end justify-between">
                            <div class=" w-full flex justify-between">
                                <FormulateInput :disabled="!disableAllField" class="w-full" :label="$t('pages.tenant_client.received_client_action_alert_emails')" :placeholder="$t('pages.tenant_client.received_client_action_alert_emails')" type="checkbox" v-model="contact.primary_contact.receive_client_action_alert" :element-class="(context, classes) => ['mr-2'].concat(classes)" />
                                <FormulateInput :disabled="!disableAllField" class="w-full" :label="$t('pages.tenant_client.received_final_report_alert_emails')" :placeholder="$t('pages.tenant_client.received_final_report_alert_emails')" type="checkbox" v-model="contact.primary_contact.receive_final_report_alert" :element-class="(context, classes) => ['mr-2'].concat(classes)" />
                            </div>
                        </div>
                        <div class="w-1/2">
                            <FormulateInput :disabled="!disableAllField" class="w-full" :label="$t('pages.tenant_client.note')" :placeholder="$t('pages.tenant_client.note')" type="textarea" v-model="contact.primary_contact.note" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                        </div>
                    </div>
                </div>

                <!-- Accounts Contact -->
                <div>
                    <h1 class="text-base-content-600 font-semibold text-lg mb-3">{{ $t('pages.tenant_client.accounts_contact') }}</h1>
                    <div class="bg-card-bg p-6 rounded-lg">
                        <div class="grid grid-cols-2 gap-6">
                            <FormulateInput :disabled="!disableAllField" class="w-full" :label="$t('pages.tenant_client.name')" :placeholder="$t('pages.tenant_client.name')" type="text" v-model="contact.accounts_contact.name" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" validation="required|matches:/^[a-zA-Z\s]+$/" :validation-messages="{ matches: $t('pages.tenant_client.rule_name_not_only_numbers_spec_symb')}"/>
                            <FormulateInput :disabled="!disableAllField" class="w-full" :label="$t('pages.tenant_client.position')" :placeholder="$t('pages.tenant_client.position')" type="text" v-model="contact.accounts_contact.position" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" validation="required|matches:/^[a-zA-Z\s]+$/" :validation-messages="{ matches: $t('pages.tenant_client.rule_position_not_only_numbers_spec_symb')}" />
                        </div>
                        <div class="grid grid-cols-2 gap-6">
                            <FormulateInput :disabled="!disableAllField" class="w-full" :label="$t('pages.tenant_client.phone')" :placeholder="$t('pages.tenant_client.phone')" type="text" v-model="contact.accounts_contact.phone" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" :validation="checkAccountsContact() && 'required'" @keypress="isIntegerNumber($event)"/>
                            <FormulateInput :disabled="!disableAllField" class="w-full" :label="$t('pages.tenant_client.email')" :placeholder="$t('pages.tenant_client.email')" type="email" v-model="contact.accounts_contact.email" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" :validation="checkAccountsContact() && 'required|email'" />
                        </div>
                        <div class="flex 2xl:justify-end justify-between">
                            <div class=" w-full flex justify-between">
                                <FormulateInput :disabled="!disableAllField" class="w-full" :label="$t('pages.tenant_client.received_client_action_alert_emails')" :placeholder="$t('pages.tenant_client.received_client_action_alert_emails')" type="checkbox" v-model="contact.accounts_contact.receive_client_action_alert" :element-class="(context, classes) => ['mr-2'].concat(classes)" />
                                <FormulateInput :disabled="!disableAllField" class="w-full" :label="$t('pages.tenant_client.received_final_report_alert_emails')" :placeholder="$t('pages.tenant_client.received_final_report_alert_emails')" type="checkbox" v-model="contact.accounts_contact.receive_final_report_alert" :element-class="(context, classes) => ['mr-2'].concat(classes)" />
                            </div>
                        </div>
                        <div class="w-1/2">
                            <FormulateInput :disabled="!disableAllField" class="w-full" :label="$t('pages.tenant_client.note')" :placeholder="$t('pages.tenant_client.note')" type="textarea" v-model="contact.accounts_contact.note" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                        </div>
                    </div>
                </div>

                <!-- Addition Contacts -->
                <div class="flex flex-col gap-3" v-if="contact.additional_contacts && contact.additional_contacts.length > 0">
                    <div v-for="(additional, index) in contact.additional_contacts" :key="index">
                        <h1 class="text-gray-700 font-semibold text-lg mb-3">{{$t('pages.tenant_client.additional_contact_v', { value: index + 1})}}</h1>
                        <div class="bg-card-bg p-6 relative rounded-lg">
                            <button @click="removeOptionalContact(index)" class="h-10 w-10 absolute top-2 right-2 rounded-full hover:bg-gray-200 flex justify-center items-center"><font-awesome-icon class="text-error" icon="trash" /></button>

                            <div class="grid grid-cols-2 gap-6">
                                <FormulateInput :disabled="!disableAllField" class="w-full" :label="$t('pages.tenant_client.name')" :placeholder="$t('pages.tenant_client.name')" type="text" :validation="checkAccountsContact(index) && 'required'" v-model="additional.name" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                                <FormulateInput :disabled="!disableAllField" class="w-full" :label="$t('pages.tenant_client.position')" :placeholder="$t('pages.tenant_client.position')" type="text" v-model="additional.position" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                            </div>
                            <div class="grid grid-cols-2 gap-6">
                                <FormulateInput :disabled="!disableAllField" class="w-full" :label="$t('pages.tenant_client.phone')" :validation="checkAccountsContact(index) && 'required'" :placeholder="$t('pages.tenant_client.phone')" type="text" v-model="additional.phone" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                                <FormulateInput :disabled="!disableAllField" class="w-full" :label="$t('pages.tenant_client.email')" :validation="checkAccountsContact(index) && 'required|email'" :placeholder="$t('pages.tenant_client.email')" type="email" v-model="additional.email" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                            </div>
                            <div class="flex 2xl:justify-end justify-between">
                                <div class=" w-full flex justify-between">
                                    <FormulateInput :disabled="!disableAllField" class="w-full" :label="$t('pages.tenant_client.received_client_action_alert_emails')" :placeholder="$t('pages.tenant_client.received_client_action_alert_emails')" type="checkbox" v-model="additional.receive_client_action_alert" :element-class="(context, classes) => ['mr-2'].concat(classes)" />
                                    <FormulateInput :disabled="!disableAllField" class="w-full" :label="$t('pages.tenant_client.received_final_report_alert_emails')" :placeholder="$t('pages.tenant_client.received_final_report_alert_emails')" type="checkbox" v-model="additional.receive_final_report_alert" :element-class="(context, classes) => ['mr-2'].concat(classes)" />
                                </div>
                            </div>
                            <div class="w-1/2">
                                <FormulateInput :disabled="!disableAllField" class="w-full" :label="$t('pages.tenant_client.note')" :placeholder="$t('pages.tenant_client.note')" type="textarea" v-model="additional.note" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="disableAllField" class="flex my-4 w-full justify-between">
                <Button :text="$t('actions.back')" type="outline" @click="$emit('handleBackSection')" />
                <Button :loader="loadingState.submit" :text="$t('pages.tenant_client.save__next')" type="primary" @click="handleSubmit(hasErrors)" />
            </div>
        </FormulateForm>
        <Button v-if="disableAllField" :disabled="contact.additional_contacts.length >= 3" :text="$t('pages.tenant_client.additional_contacts')" type="primary" class="absolute top-2.5 right-6" @click="addAdditionalContact()" />
    </div>
</template>

<script>
import Button from "@shared/components/button";
import Loader from "@shared/loader";
import axios from "@/axios";
import { fetchClientContacts, createAndUpdateClientContacts } from "../services";
import { checkPermission } from "@shared/utils/functions"
import { isIntegerNumber } from "@/plugins/functions";

const contactSchema = {
    name: "",
    position: "",
    phone: "",
    email: "",
    receive_client_action_alert: false,
    receive_final_report_alert: false,
    note: "",
};
export default {
    name: "client-admin-contacts",
    components: {
        Button,
        Loader,
    },
    props: {
        isClientOnboarded:{
            type:Boolean,
            default: false
        }
    },
    data() {
        return {
            contact: {
                primary_contact: { ...contactSchema },
                accounts_contact: { ...contactSchema },
                additional_contacts: [],
            },
            clientId: null,
            loadingState: {
                submit: false,
                fetching: false,
            },
        };
    },
    async mounted() {
        await this.getClientContacts();
    },
    computed:{
        disableAllField(){
            // if(this.$route.name === 'create-client' && !this.isClientOnboarded)  return this.checkPermission('client.create')
            if(this.$route.name === 'client-details' && !this.isClientOnboarded) return this.checkPermission('client.create') 
            else if(this.$route.name === 'client-details' && this.isClientOnboarded) return this.checkPermission('client.edit')
            else return false
        },
    },
    methods: {
        isIntegerNumber,
        checkPermission,
        async handleSubmit(hasErrors) {
            this.loadingState.submit = true;
            if (hasErrors) {
                this.$toast.error(this.$t('pages.tenant_client.please_fill_all_the_required_fields'));
                this.loadingState.submit = false;
            } else {
                let primary_contact = {
                    ...this.contact.primary_contact,
                    contact_type: "Primary Contact",
                    // name: this.contact.primary_contact.name,
                    // position: this.contact.primary_contact.position,
                    // phone: this.contact.primary_contact.phone,
                    // email: this.contact.primary_contact.email,
                    // receive_client_action_alert: this.contact.primary_contact.receive_client_action_alert,
                    // receive_final_report_alert: this.contact.primary_contact.receive_final_report_alert,
                    // note: this.contact.primary_contact.note,
                };
                let accounts_contact = {
                    ...this.contact.accounts_contact,
                    contact_type: "Accounts Contact",
                    // name: this.contact.primary_contact.name,
                    // position: this.contact.primary_contact.position,
                    // phone: this.contact.primary_contact.phone,
                    email: this.contact.accounts_contact.email || null,
                    // receive_client_action_alert: this.contact.primary_contact.receive_client_action_alert,
                    // receive_final_report_alert: this.contact.primary_contact.receive_final_report_alert,
                    // note: this.contact.primary_contact.note,
                };
                console.log('accounts_contact :>> ', accounts_contact);
                let additional_contact = this.contact.additional_contacts?.map((el) => ({ ...el, contact_type: "Additional Contact", email: el.email || null }));
                let contacts = [];
                contacts.push(primary_contact, accounts_contact, ...additional_contact);
                try {
                    let response = await createAndUpdateClientContacts(this.clientId, contacts);
                    this.contact = this.convertResponse(response);
                    this.$emit("handleNextSection");
                    this.$toast.success(response.message || this.$t('pages.tenant_client.saved'));
                } catch (error) {
                    this.loadingState.submit = false;
                    this.$toast.error(error.response.data.detail || this.$t('pages.tenant_client.errorresponsedatadetail__failed_to_update_contact'));
                }
                this.loadingState.submit = false;
            }
        },
        async getClientContacts() {
            this.loadingState.fetching = true;
            this.clientId = this.$route.params.clientId;
            if (this.clientId) {
                try {
                    let response = await fetchClientContacts(this.clientId);
                    this.contact = this.convertResponse(response);
                } catch (error) {
                    console.log(error);
                }
            }
            this.loadingState.fetching = false;
        },

        convertResponse(contacts) {
            if (contacts.length > 0) {
                let empty_contact = {
                    name: "",
                    position: "",
                    phone: "",
                    email: "",
                    receive_client_action_alert: false,
                    receive_final_report_alert: false,
                    note: "",
                };
                let primary_contact = contacts?.find((el) => el.contact_type === "Primary Contact") || empty_contact;
                let accounts_contact = contacts?.find((el) => el.contact_type === "Accounts Contact") || empty_contact;
                let additional_contacts = contacts?.filter((el) => el.contact_type === "Additional Contact");
                let new_contacts = {
                    primary_contact,
                    accounts_contact,
                    additional_contacts,
                };
                return new_contacts;
            } else {
                return this.contact;
            }
        },
        addAdditionalContact() {
            this.contact.additional_contacts.push({ ...contactSchema });
        },
        async removeOptionalContact(index) {
            let contact = this.contact.additional_contacts[index];
            if (contact && contact.id) {
                let url = `/client/${this.clientId}/contact/${contact.id}`;
                try {
                    let { data } = await axios.delete(url);
                    this.contact = { ...this.contact, additional_contacts: this.contact.additional_contacts.filter((el, id) => id !== index) };
                    this.$toast.success(data.message || this.$t('pages.tenant_client.success_remove'));
                } catch (error) {
                    this.$toast.error(error.response.data.detail || this.$t('pages.tenant_client.error_remove'));
                }
            } else {
                this.contact = { ...this.contact, additional_contacts: this.contact.additional_contacts.filter((el, id) => id !== index) };
            }
        },
        checkAccountsContact(index=-1) {
            let obj = {}
            if(index!=-1) {
                obj = this.contact.additional_contacts[index]
            } else {
                obj = this.contact.accounts_contact;
            }
            let { name, phone, email, position, receive_client_action_alert, receive_final_report_alert, note } = obj;
            return name?.trim().length > 0 || phone?.trim().length > 0 || email?.trim().length > 0 || position?.trim().length > 0 || note?.trim().length > 0 || receive_client_action_alert || receive_final_report_alert;
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .formulate-input-grouping {
        display: flex;
        flex-direction: column;
        @apply gap-6;
        @apply border-none;
    }
    .formulate-input-group-repeatable {
        @apply bg-white;
    }
    .formulate-input-element.formulate-input-element--group.formulate-input-group {
        @apply border-none;
    }
}
</style>
