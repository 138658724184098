<!-- Localized -->
<template>
  <div class="section-header flex w-full gap-2">
    <div class="flex items-center gap-2">
      <div
        v-if="$slots['left:before']"
        class="section-header__left--before"
      >
        <slot name="left:before" />
      </div>
      <Button
        v-if="back"
        :text="$t('actions.back')"
        class="btn btn-outline btn--dark--outline btn-xs"
        @click="onBack"
      />
      <div
        v-if="$slots['left:after']"
        class="section-header__left--after"
      >
        <slot name="left:after" />
      </div>
    </div>

    <div
      v-if="$slots.default"
      class="flex items-center gap-2 ml-auto"
    >
      <slot />
    </div>
  </div>
</template>

<script>
import Button from "@shared/components/button";

export default {
  components: {
    Button,
  },
  props: {
    back: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onBack() {
      this.$emit("back");
    },
  },
}
</script>

<style lang="scss" scoped>
</style>