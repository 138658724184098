<!-- Localized -->
<template>
    <div class="flex flex-col gap-2 flex-1">
        <SectionHeader
            :back="!!selectedGroup"
            @back="backToGroups"
        >
            <Button
                v-if="checkPermission('client.read') && checkPermission('client.edit')"
                :text="`${selectedGroup ? 'Add User' : 'Add Group'}`"
                @click="selectedGroup ? showAddUserModal() : handleShowModal()"
                type="primary"
                class="ml-auto"
            />
        </SectionHeader>
        <div v-if="selectedGroup && selectedGroup.id">
            <div class="card bg-white rounded-md shadow p-6 flex flex-row gap-5 mb-6 items-center">
                <div class="flex flex-col gap-2 w-5/12">
                    <span class="text-gray-500 text-md">{{ $t('pages.tenant_client.group_name') }}</span>
                    <Input :disabled="!checkPermission('client.edit')" class="h-11" :placeholder="$t('pages.tenant_client.group_name')" bg="white" v-model="selectedGroup.title" name="groupname"> </Input>
                </div>

                <div class="flex flex-col gap-2 w-6/12">
                    <span class="text-gray-500 text-md">{{ $t('pages.tenant_client.group_description') }}</span>
                    <textarea :disabled="!checkPermission('client.edit')" class="rounded-lg textarea-bordered textarea textarea h-8 min-w-full resize-none" :placeholder="$t('pages.tenant_client.group_description')" v-model="selectedGroup.description" name="groupdescription"> </textarea>
                </div>
                <div class="flex-1 flex gap-5 w-2/12 items-center">
                    <span class="text-gray-500 text-md"> </span>
                    <div class="flex mt-7 gap-4">
                        <button :disabled="!checkPermission('client.edit')" :class="!checkPermission('client.edit') && 'cursor-default pointer-events-none opacity-50'" class="h-10 w-10 flex justify-center items-center rounded-full bg-gray-100 hover:bg-gray-200" @click="updateGrp">
                            <font-awesome-icon icon="save" class="text-primary text-xl cursor-pointer" />
                        </button>
                        <button :disabled="!checkPermission('client.edit')" :class="!checkPermission('client.edit') && 'cursor-default pointer-events-none opacity-50'" class="h-10 w-10 bg-gray-100 flex justify-center rounded-full items-center hover:bg-gray-200" @click="deleteGroup(selectedGroup)">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="text-red-600 hover:text-red-700 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            <vue-good-table class="flex-1" :isLoading="loadingStates.users" :columns="users.columns" :rows="users.rows" :key="selectedGroup.id">
                <template slot="table-row" slot-scope="props">
                    <div v-if="props.column.field === 'role_ids'" class="flex gap-3">
                        <div class="flex gap-2">
                            <template v-for="role in props.row.role_ids">
                                <div class="bg-blue-100 text-primary text-sm shadow truncate px-1 rounded font-normal" :title="role.title" :key="role.role_id">{{ role.title }}</div>
                            </template>
                        </div>
                    </div>
                    <div v-else-if="props.column.field === 'group_ids'" class="flex gap-3">
                        <div class="flex gap-2">
                            <template v-for="group in props.row.group_ids">
                                <div class="bg-blue-100 text-primary text-sm shadow truncate px-1 rounded font-normal" :title="group.title" :key="group.group_id">{{ group.title }}</div>
                            </template>
                        </div>
                    </div>
                    <div v-else-if="props.column.field === 'actions'"  class="flex justify-center gap-3">
                        <button :disabled="!checkPermission('client.edit')" :class="[!checkPermission('client.edit') && 'cursor-not-allowed opacity-50 pointer-events-none']" @click="removeUser(props.row.id)" class="h-6 w-6">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="text-red-600 hover:text-red-700">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                            </svg>
                        </button>
                    </div>
                </template>
                <template #loadingContent>
                    <div class="w-full py-10 justify-center flex">
                        <Loader />
                    </div>
                </template>
            </vue-good-table>
        </div>
        <vue-good-table :isLoading="loadingStates.groups" v-else class="flex-1" :columns="groups.columns" :rows="filteredRows(groups.rows)" :pagination-options="{ enabled: true, mode: 'records'}">
            <template #column-filter="{ column }">
                    <Dropdown   
                        class="filter-dropdown text-center" 
                        :placeholder="$t('actions.select')"
                        v-if="column?.filterOptions?.enabled"
                        :options="getFilterOptions(column)" 
                        v-model="filters[column.field]"
                        @input="(value) => { updateFilters(column, value)}" 
                        :config="{ limit: 1 }" 
                        :colors="{ optionText: 'black' }"
                        :reposition="true"
                        searchable
                        multiple
                        maxWidth="100%"
                        width="100px"
                        minWidth="100%"
                    >
                    </Dropdown> 
                </template>
            <template slot="table-row" slot-scope="props">
                <div v-if="props.column.field === 'title'" class="flex gap-3">
                    <p class="text-primary cursor-pointer" @click="handleGroupClick(props.row)">{{ props.row.title }}</p>
                </div>
                <div v-else-if="props.column.field === 'action'" class="flex gap-3 justify-center">
                    <button @click="deleteGroup(props.row)" class="h-6 w-6 disabled:opacity-50 "
                        :class="(!checkPermission('client.edit') ) && 'pointer-events-none cursor-wait' "
                        :disabled="!checkPermission('client.edit')">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                            class="text-red-600 hover:text-red-700">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                        </svg>
                    </button>
                </div>
            </template>
            <template #loadingContent>
                <div class="w-full py-10 justify-center flex">
                    <Loader />
                </div>
            </template>
        </vue-good-table>
        <GroupModal :groupname.sync="group.groupname" :groupdescription.sync="group.groupdescription" :disabled="!group.groupname || !group.groupdescription" @submit="addGroup" />
        <ModalConfirm :title="$t('general.are_you_sure')" :message="$t('pages.tenant_client.please_confirm_youre_about_to_delete_the_field')" ref="confirm-popup"> </ModalConfirm>
        <ListModal ref="add-user-modal" @handleFilterResult="handleFilterResult($event)" :lists="getFilteredUsers" :isLoading="loadingStates.fetchingUsers" modalTitle="Add User">
            <template #item="{ item }">
                <div class="flex items-center flex-1 justify-between">
                    <span class="truncate">{{ item.user_name }}</span>
                    <Button type="primary" class="btn-xs" :text="$t('actions.add')" @click="assignUserToGroup(item)" :disabled="loadingStates && loadingStates.assignUser"> </Button>
                </div>
            </template>
        </ListModal>
    </div>
</template>

<script>
import Button from "@shared/components/button";
import GroupModal from "@/pages/group-details/create-group-modal";
// import Dropdown from "@shared/components/dropdown-base";
import axios from "@/axios";
import Input from "@shared/input";
const ModalConfirm = () => import("@/components/modal-confirm");
import Loader from "@shared/loader";
import ListModal from "@shared/components/listModal";
import { checkPermission } from "@shared/utils/functions";
import { uniq } from "lodash";
import Dropdown from "@shared/components/dropdown-base";
import { SectionHeader } from "./UI";

export default {
    name: "groups",
    components: {
        Button,
        GroupModal,
        Loader,
        Input,
        ModalConfirm,
        ListModal,
        Dropdown,
        SectionHeader,
    },
    props: {},
    data() {
        return {
            groups: {
                columns: [
                    {
                        label: this.$t('pages.tenant_client.group_name'),
                        field: "title",
                        filterOptions: {
                            enabled: true, // enable filter for this column
                            isLoading: false,
                        },
                    },
                    {
                        label: this.$t('pages.tenant_client.no_of_users'),
                        field: "user_count",
                        filterOptions: {
                            enabled: true, // enable filter for this column
                            isLoading: false,
                        },
                    },
                    {
                        label: this.$t('pages.tenant_client.group_decription'),
                        field: "description",
                        filterOptions: {
                            enabled: true, // enable filter for this column
                            isLoading: false,
                        },
                    },
                    {
                        label: this.$t('pages.tenant_client.action'),
                        field: "action",
                    },
                ],
                rows: [],
            },
            users: {
                columns: [
                    {
                        label: this.$t('pages.tenant_client.user_name'),
                        field: "user_name",
                    },
                    {
                        label: this.$t('pages.tenant_client.email'),
                        field: "email",
                    },
                    {
                        label: this.$t('pages.tenant_client.user_position'),
                        field: "position",
                    },
                    {
                        label: this.$t('pages.tenant_client.telephone'),
                        field: "mobile",
                    },
                    {
                        label: this.$t('pages.tenant_client.actions'),
                        field: "actions",
                    },
                    // {
                    //     label: "Role",
                    //     field: "role_ids",
                    // },
                    // {
                    //     label: "Group",
                    //     field: "group_ids",
                    // },
                ],
                rows: [],
            },
            tenant_id: null,
            clientId: null,
            selectedGroup: null,
            group: {
                groupname: "",
                groupdescription: "",
                active: true,
            },
            loadingStates: {
                groups: false,
                users: false,
                assignUser: false,
                fetchingUsers: false,
            },
            searchState: {
                userSearch: "",
            },
            usersList: [],
            filters:{
                title: [],
                user_count: [],
                description: [],
            },
        };
    },
    async mounted() {
        this.clientId = this.$route.params.clientId;
        this.tenant_id = this.$store.getters.getTenantId;
        if (this.$route.query.group) {
            await this.getGroupDetails();
            await this.getGroupusers();
        } else {
            await this.getGroups();
        }
    },
    computed: {
        getFilteredUsers() {
            return this.usersList.filter((user) => user?.user_name?.toLowerCase().includes(this.searchState?.userSearch?.toLowerCase()) && !this.users.rows.some((group_user) => group_user.id === user.id));
        },
    },
    methods: {
        checkPermission,
        async handleGroupClick({ id }) {
            this.$router.push({ query: { group: id } });
            await this.getGroupDetails(id);
            await this.getGroupusers();
        },
        backToGroups() {
            this.selectedGroup = null;
            this.$router.push({ query: {} });
            this.getGroups();
        },
        handleShowModal() {
            this.group = {
                groupname: "",
                groupdescription: "",
                active: true,
            };
            this.$modal.show("group-modal");
        },
        hanldeCloseModal() {
            this.$modal.hide("group-modal");
        },
        async getGroups() {
            this.loadingStates.groups = true;
            // let items = [];
            try {
                this.loading = true;
                let { data } = await axios.get(`group/client/${this.clientId}`);
                this.groups.rows = data;
            } catch (error) {
                console.log("error", error);
            }
            this.loading = false;
            this.loadingStates.groups = false;
            // return items;
        },
        async addGroup() {
            try {
                let { data } = await axios.post("group/client", {
                    title: this.group.groupname,
                    description: this.group.groupdescription,
                    active: this.group.active,
                    client_id: this.clientId,
                });
                this.$toast.success(data.message || this.$t('pages.tenant_client.success_group_created'));
                this.groups.rows.push({
                    title: this.group.groupname,
                    description: this.group.groupdescription,
                    user_count: 0,
                    active: this.group.active,
                    id: data.group_id,
                });
                this.$router.push({ query: { group: data.group_id } });
                await this.getGroupDetails();
                await this.getGroupusers();
                this.hanldeCloseModal();
            } catch (error) {
                this.$toast.error(error.response.data.detail || this.$t('pages.tenant_client.error_group_delete'));
            }
        },
        async deleteGroup(row) {
            const promise = await this.$refs["confirm-popup"].show({
                title: "Are you sure?",
                message: "This group  will be deleted permanently. Please confirm to continue deleting this group.",
                buttonText: 'Confirm'
            });
            if (promise) {
                try {
                    let { data } = await axios.delete(`group/client/${this.clientId}/group_id/${row.id}`);
                    this.$toast.success(data.message || this.$t('pages.tenant_client.success_group_delete'));
                    this.$router.push({ query: {} });
                    this.selectedGroup = null;
                    this.getGroups();
                } catch (error) {
                    this.$toast.error(error.response.data.detail, this.$t('pages.tenant_client.error_group_delete'));
                    console.log("error", error);
                }
            }
            this.$refs["confirm-popup"].close();
        },
        async updateGrp() {
            try {
                let { data } = await axios.put(`group/client/${this.selectedGroup.id}`, {
                    title: this.selectedGroup.title,
                    description: this.selectedGroup.description,
                    active: true,
                    client_id: this.clientId,
                });
                this.$toast.success(data.message, this.$t('pages.tenant_client.success_group_update'));
            } catch (error) {
                this.$toast.error(error.response.data.detail || this.$t('pages.tenant_client.error_group_delete'));
                console.log("error", error);
            }
        },
        async getGroupDetails(id=null) {
            let url = `group/client/${this.clientId}/group/${id ? id : this.$route.query.group}`;
            try {
                let { data } = await axios.get(url);
                this.selectedGroup = data;
            } catch (error) {
                console.log(error, "Error");
            }
        },
        async getGroupusers() {
            this.loadingStates.users = true;
            try {
                let { data } = await axios.get(`group/user/group_id/${this.selectedGroup.id}`);
                this.users.rows = data;
            } catch (error) {
                console.log(error, "<<<<error while fetching users");
            }
            this.loadingStates.users = false;
        },
        async showAddUserModal() {
            this.$refs["add-user-modal"].showListModal();
            this.searchState.userSearch = "";
            this.loadingStates.assignUser = false;
            await this.getUsersList();
        },
        async getUsersList() {
            this.loadingStates.fetchingUsers = true;
            try {
                let { data } = await axios.get(`user/client/${this.clientId}`);
                this.usersList = data;
            } catch (error) {
                console.log(error, "<<<<error while fetching users");
            }
            this.loadingStates.fetchingUsers = false;
        },
        handleFilterResult(value) {
            this.searchState.userSearch = value;
        },
        async assignUserToGroup(user) {
            console.log(user, "Assd");
            this.loadingStates.assignUser = true;
            try {
                let url = `/group/client/user`;
                let payload = {
                    user_id: user.id,
                    group_id: this.selectedGroup.id,
                };
                let { data } = await axios.post(url, payload);
                this.users.rows.push(user);
                this.$toast.success(data.message || this.$t('pages.tenant_client.success_add_group_user'));
                console.log(data, ">>>>data");
            } catch (error) {
                this.$toast.error(error.response.data.detail || this.$t('pages.tenant_client.error_add_group_user'));
            }
            this.loadingStates.assignUser = false;
        },
        async removeUser(id) {
            const promise = await this.$refs["confirm-popup"].show({
                title: "Are you sure?",
                message: "This user  will be deleted permanently. Please confirm to continue deleting this user.",
                buttonText: 'Confirm'
            });
            if (promise) {
                try {
                    let { data } = await axios.delete(`group/client/group_id/${this.selectedGroup.id}/user/${id}`);
                    this.$toast.success(data.message || this.$t('pages.tenant_client.success_user_delete'));
                    this.users.rows = this.users.rows.filter((x) => x.id !== id);
                } catch (error) {
                    this.$toast.error(error.response.data.detail || this.$t('pages.tenant_client.error_user_remove'));
                    console.log("error", error);
                }
            }
            this.$refs["confirm-popup"].close();
        },
        getFilterOptions(column){
            let result = this.groups.rows.length ? this.groups.rows.filter((el)=> el[column.field] != null ).map((el)=>el[column.field]): [];
            if(column.field === "role_ids" || column.field === "group_ids"){
                return uniq(result.flat().map((el)=>el.title)) || []
            }
            return uniq(result)
        },
        updateFilters(column, value) {
            if (this.filters?.[column.field]) this.filters[column.field] = value;
        },
        filteredRows(result){
            for (let filter in this.filters) {
                if (this.filters[filter]?.length) { 
                     result = result.filter((el) => this.filters[filter].includes(el[filter]));
                }
            }
            return result;
        },
    },
    watch: {
        selectedGroup: function (val) {
            if (val && val.title) {
                this.$emit("setBredCrumbs", val.title);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@shared/assets/mixins/mixins.scss";
@include tableComponent;
.filter-dropdown {
    box-shadow: none !important;
    @apply border-transparent border  bg-white;
}
</style>
