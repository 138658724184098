var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loadingState.address)?_c('div',{staticClass:"bg-card-bg p-6 rounded-lg py-24 flex items-center justify-center"},[_c('Loader')],1):_c('FormulateForm',{scopedSlots:_vm._u([{key:"default",fn:function({ hasErrors }){return [_c('div',{staticClass:"flex flex-col gap-6"},[_c('div',[_c('h1',{staticClass:"text-gray-700 font-semibold text-lg mb-3"},[_vm._v(_vm._s(_vm.$t('pages.tenant_client.primary_address')))]),_c('div',{staticClass:"bg-card-bg p-6 rounded-lg"},[_c('div',{staticClass:"grid grid-cols-2 gap-6"},[_c('FormulateInput',{staticClass:"w-full",attrs:{"type":"text","label":_vm.$t('pages.tenant_client.line_1'),"placeholder":_vm.$t('pages.tenant_client.line_1'),"validation":"required","element-class":(context, classes) => ['flex-1 min-w-full'].concat(classes),"disabled":!_vm.disableAllField},model:{value:(_vm.address.primary_address.line_1),callback:function ($$v) {_vm.$set(_vm.address.primary_address, "line_1", $$v)},expression:"address.primary_address.line_1"}}),_c('FormulateInput',{staticClass:"w-full",attrs:{"type":"text","label":_vm.$t('pages.tenant_client.line_2'),"placeholder":_vm.$t('pages.tenant_client.line_2'),"element-class":(context, classes) => ['flex-1 min-w-full'].concat(classes),"disabled":!_vm.disableAllField},model:{value:(_vm.address.primary_address.line_2),callback:function ($$v) {_vm.$set(_vm.address.primary_address, "line_2", $$v)},expression:"address.primary_address.line_2"}})],1),_c('div',{staticClass:"grid grid-cols-3 gap-6"},[_c('FormulateInput',{staticClass:"w-full",attrs:{"type":"text","label":_vm.$t('pages.tenant_client.city'),"name":"City","placeholder":_vm.$t('pages.tenant_client.city'),"validation":"required|matches:/^[a-zA-Z\\s]+$/","validation-messages":{ 
                                matches: 'City name cannot contain number or any special characters'
                            },"element-class":(context, classes) => ['flex-1 min-w-full'].concat(classes),"disabled":!_vm.disableAllField},model:{value:(_vm.address.primary_address.city),callback:function ($$v) {_vm.$set(_vm.address.primary_address, "city", $$v)},expression:"address.primary_address.city"}}),_c('FormulateInput',{attrs:{"type":"multi-select","variant":"alt","multiple":false,"label":_vm.$t('pages.tenant_client.country'),"placeholder":_vm.$t('pages.tenant_client.country'),"validation":"required","searchable":"","options":_vm.countries,"config":{ label: 'name', trackBy: 'name' },"colors":{
                                text: '#282828',
                                bg: 'white',
                                svg: '#282828',
                                border: '#bcbcbc',
                            },"loading":_vm.loadingState.country,"disabled":!_vm.disableAllField},on:{"input":function($event){return _vm.updateCountry($event, 'primary_address')}},model:{value:(_vm.address.primary_address.country),callback:function ($$v) {_vm.$set(_vm.address.primary_address, "country", $$v)},expression:"address.primary_address.country"}}),_c('FormulateInput',{staticClass:"w-full",attrs:{"type":"text","label":_vm.$t('pages.tenant_client.postcode'),"placeholder":_vm.$t('pages.tenant_client.postcode'),"validation":"required|matches:/^[a-zA-Z0-9\\s\\-]+$/","validation-messages":{ 
                                matches: 'Only characters supported are alphanumeric, - and spaces' 
                            },"element-class":(context, classes) => ['flex-1 min-w-full'].concat(classes),"disabled":!_vm.disableAllField},model:{value:(_vm.address.primary_address.zip_code),callback:function ($$v) {_vm.$set(_vm.address.primary_address, "zip_code", $$v)},expression:"address.primary_address.zip_code"}})],1)])]),_c('div',[_c('div',{staticClass:"flex gap-2 items-center mb-3"},[_c('h1',{staticClass:"text-gray-700 font-semibold text-lg mr-4"},[_vm._v(_vm._s(_vm.$t('pages.tenant_client.billing_address')))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.saveAsPrimary),expression:"saveAsPrimary"}],staticClass:"checkbox checkbox-sm",attrs:{"type":"checkbox","id":"same_as_primary"},domProps:{"checked":Array.isArray(_vm.saveAsPrimary)?_vm._i(_vm.saveAsPrimary,null)>-1:(_vm.saveAsPrimary)},on:{"input":(input) => _vm.sameAsPrimaryAddress(input.target.checked),"change":function($event){var $$a=_vm.saveAsPrimary,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.saveAsPrimary=$$a.concat([$$v]))}else{$$i>-1&&(_vm.saveAsPrimary=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.saveAsPrimary=$$c}}}}),_c('label',{staticClass:"text-gray-600 text-sm",attrs:{"for":"same_as_primary"}},[_vm._v(_vm._s(_vm.$t('pages.tenant_client.same_as_primary_address')))])]),_c('div',{staticClass:"bg-card-bg p-6 rounded-lg"},[_c('div',{staticClass:"grid grid-cols-2 gap-6"},[_c('FormulateInput',{staticClass:"w-full",class:{'disableInput': _vm.isDisabled},attrs:{"type":"text","label":_vm.$t('pages.tenant_client.line_1'),"placeholder":_vm.$t('pages.tenant_client.line_1'),"validation":_vm.checkBillingAddressRequired ? [
                                ['required'],
                                ['matches', /^[a-zA-Z\s]+$/]
                            ] : [],"validation-messages":{ matches: 'Line 1 cannot contain number or any special characters'},"element-class":(context, classes) => ['flex-1 min-w-full'].concat(classes),"disabled":!_vm.disableAllField || _vm.saveAsPrimary},model:{value:(_vm.address.billing_address.line_1),callback:function ($$v) {_vm.$set(_vm.address.billing_address, "line_1", $$v)},expression:"address.billing_address.line_1"}}),_c('FormulateInput',{staticClass:"w-full",class:{'disableInput': _vm.isDisabled},attrs:{"type":"text","label":_vm.$t('pages.tenant_client.line_2'),"placeholder":_vm.$t('pages.tenant_client.line_2'),"element-class":(context, classes) => ['flex-1 min-w-full'].concat(classes),"disabled":!_vm.disableAllField || _vm.saveAsPrimary},model:{value:(_vm.address.billing_address.line_2),callback:function ($$v) {_vm.$set(_vm.address.billing_address, "line_2", $$v)},expression:"address.billing_address.line_2"}})],1),_c('div',{staticClass:"grid grid-cols-3 gap-6"},[_c('FormulateInput',{staticClass:"w-full",class:{'disableInput': _vm.isDisabled},attrs:{"type":"text","label":_vm.$t('pages.tenant_client.city'),"placeholder":_vm.$t('pages.tenant_client.city'),"element-class":(context, classes) => ['flex-1 min-w-full'].concat(classes),"validation":_vm.checkBillingAddressRequired ? [
                                ['required'],
                                ['matches', /^[a-zA-Z\s]+$/]
                            ] : [],"validation-messages":{ matches: 'City name cannot contain number or any special characters'},"disabled":!_vm.disableAllField || _vm.saveAsPrimary},model:{value:(_vm.address.billing_address.city),callback:function ($$v) {_vm.$set(_vm.address.billing_address, "city", $$v)},expression:"address.billing_address.city"}}),_c('FormulateInput',{attrs:{"type":"multi-select","variant":"alt","multiple":false,"label":_vm.$t('pages.tenant_client.country1'),"placeholder":_vm.$t('pages.tenant_client.country'),"validation":_vm.checkBillingAddressRequired ? [
                                ['required']
                            ] : [],"searchable":"","options":_vm.countries,"config":{ label: 'name', trackBy: 'name' },"colors":{
                                text: '#282828',
                                bg: 'white',
                                svg: '#282828',
                                border: '#bcbcbc',
                            },"loading":_vm.loadingState.country,"disabled":!_vm.disableAllField || _vm.saveAsPrimary},on:{"input":function($event){return _vm.updateCountry($event, 'billing_address')}},model:{value:(_vm.address.billing_address.country),callback:function ($$v) {_vm.$set(_vm.address.billing_address, "country", $$v)},expression:"address.billing_address.country"}}),_c('FormulateInput',{staticClass:"w-full",class:{'disableInput': _vm.isDisabled},attrs:{"type":"text","label":_vm.$t('pages.tenant_client.postcode'),"placeholder":_vm.$t('pages.tenant_client.postcode'),"element-class":(context, classes) => ['flex-1 min-w-full'].concat(classes),"validation":_vm.checkBillingAddressRequired ? [
                                ['required'],
                                ['matches', /^[a-zA-Z0-9\\s\\-]+$/]
                            ] : [],"validation-messages":{ matches: 'Only characters supported are alphanumeric, - and spaces' },"disabled":!_vm.disableAllField || _vm.saveAsPrimary},model:{value:(_vm.address.billing_address.zip_code),callback:function ($$v) {_vm.$set(_vm.address.billing_address, "zip_code", $$v)},expression:"address.billing_address.zip_code"}})],1)])])]),(_vm.disableAllField)?_c('div',{staticClass:"flex my-4 justify-between w-full"},[_c('Button',{attrs:{"text":_vm.$t('actions.back'),"type":"outline"},on:{"click":function($event){return _vm.$emit('handleBackSection')}}}),_c('Button',{attrs:{"text":_vm.$t('pages.tenant_client.save__next'),"type":"primary","loader":_vm.loadingState.submit},on:{"click":function($event){return _vm.handleSubmit(hasErrors)}}})],1):_vm._e()]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }