<!-- Localized -->
<template>
    <div>
        <div class="bg-card-bg p-6 rounded-lg py-24 flex items-center justify-center" v-if="loadingState.address">
            <Loader />
        </div>
        <FormulateForm #default="{ hasErrors }" v-else>
            <div class="flex flex-col gap-6">
                <!-- Primary Contact -->
                <div>
                    <h1 class="text-gray-700 font-semibold text-lg mb-3">{{ $t('pages.tenant_client.primary_address') }}</h1>
                    <div class="bg-card-bg p-6 rounded-lg">
                        <div class="grid grid-cols-2 gap-6">
                            <FormulateInput 
                                v-model="address.primary_address.line_1"
                                type="text" 
                                :label="$t('pages.tenant_client.line_1')" 
                                :placeholder="$t('pages.tenant_client.line_1')"   
                                validation="required"
                                class="w-full"
                                :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" 
                                :disabled="!disableAllField"
                            />
                            <FormulateInput 
                                v-model="address.primary_address.line_2"
                                type="text" 
                                :label="$t('pages.tenant_client.line_2')" 
                                :placeholder="$t('pages.tenant_client.line_2')" 
                                class="w-full" 
                                :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
                                :disabled="!disableAllField"  
                            />
                        </div>
                        <div class="grid grid-cols-3 gap-6">
                            <FormulateInput 
                                type="text" 
                                v-model="address.primary_address.city" 
                                :label="$t('pages.tenant_client.city')" 
                                name="City" 
                                :placeholder="$t('pages.tenant_client.city')" 
                                validation="required|matches:/^[a-zA-Z\s]+$/" 
                                :validation-messages="{ 
                                    matches: 'City name cannot contain number or any special characters'
                                }"
                                class="w-full"   
                                :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" 
                                :disabled="!disableAllField"
                            />

                            <FormulateInput
                                v-model="address.primary_address.country"
                                type="multi-select"
                                variant="alt"
                                :multiple="false"
                                :label="$t('pages.tenant_client.country')"
                                :placeholder="$t('pages.tenant_client.country')"
                                validation="required" 
                                searchable
                                :options="countries"
                                :config="{ label: 'name', trackBy: 'name' }"
                                :colors="{
                                    text: '#282828',
                                    bg: 'white',
                                    svg: '#282828',
                                    border: '#bcbcbc',
                                }"
                                :loading="loadingState.country"
                                :disabled="!disableAllField"
                                @input="updateCountry($event, 'primary_address')"
                            />

                            <FormulateInput 
                                v-model="address.primary_address.zip_code" 
                                type="text" 
                                :label="$t('pages.tenant_client.postcode')" 
                                :placeholder="$t('pages.tenant_client.postcode')" 
                                validation="required|matches:/^[a-zA-Z0-9\s\-]+$/" 
                                :validation-messages="{ 
                                    matches: 'Only characters supported are alphanumeric, - and spaces' 
                                }"
                                class="w-full" 
                                :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
                                :disabled="!disableAllField"  
                            />
                        </div>
                    </div>
                </div>

                <!-- Accounts Contact -->
                <div>
                    <div class="flex gap-2 items-center mb-3">
                        <h1 class="text-gray-700 font-semibold text-lg mr-4">{{ $t('pages.tenant_client.billing_address') }}</h1>
                        <input 
                            v-model="saveAsPrimary"
                            type="checkbox" 
                            id="same_as_primary" 
                            class="checkbox checkbox-sm" 
                            @input="(input) => sameAsPrimaryAddress(input.target.checked)" 
                        />
                        <label for="same_as_primary" class="text-gray-600 text-sm">{{ $t('pages.tenant_client.same_as_primary_address') }}</label>
                    </div>
                    <div class="bg-card-bg p-6 rounded-lg">
                        <div class="grid grid-cols-2 gap-6">
                            <FormulateInput 
                                v-model="address.billing_address.line_1"
                                type="text" 
                                :label="$t('pages.tenant_client.line_1')" 
                                :placeholder="$t('pages.tenant_client.line_1')" 
                                class="w-full" 
                                :class="{'disableInput': isDisabled}"
                                :validation="checkBillingAddressRequired ? [
                                    ['required'],
                                    ['matches', /^[a-zA-Z\s]+$/]
                                ] : []"
                                :validation-messages="{ matches: 'Line 1 cannot contain number or any special characters'}" 
                                :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"  
                                :disabled="!disableAllField || saveAsPrimary" 
                            />

                            <FormulateInput 
                                v-model="address.billing_address.line_2" 
                                type="text" 
                                :label="$t('pages.tenant_client.line_2')" 
                                :placeholder="$t('pages.tenant_client.line_2')"
                                class="w-full" 
                                :class="{'disableInput': isDisabled}" 
                                :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" 
                                :disabled="!disableAllField || saveAsPrimary" 
                            />
                        </div>
                        <div class="grid grid-cols-3 gap-6">
                            <FormulateInput 
                                v-model="address.billing_address.city" 
                                type="text" 
                                :label="$t('pages.tenant_client.city')" 
                                :placeholder="$t('pages.tenant_client.city')" 
                                class="w-full" 
                                :class="{'disableInput': isDisabled}"
                                :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" 
                                :validation="checkBillingAddressRequired ? [
                                    ['required'],
                                    ['matches', /^[a-zA-Z\s]+$/]
                                ] : []" 
                                :validation-messages="{ matches: 'City name cannot contain number or any special characters'}" 
                                :disabled="!disableAllField || saveAsPrimary" 
                            />

                            <FormulateInput
                                v-model="address.billing_address.country"
                                type="multi-select"
                                variant="alt"
                                :multiple="false"
                                :label="$t('pages.tenant_client.country1')"
                                :placeholder="$t('pages.tenant_client.country')"
                                :validation="checkBillingAddressRequired ? [
                                    ['required']
                                ] : []" 
                                searchable
                                :options="countries"
                                :config="{ label: 'name', trackBy: 'name' }"
                                :colors="{
                                    text: '#282828',
                                    bg: 'white',
                                    svg: '#282828',
                                    border: '#bcbcbc',
                                }"
                                :loading="loadingState.country"
                                :disabled="!disableAllField || saveAsPrimary" 
                                @input="updateCountry($event, 'billing_address')"
                            />

                            <FormulateInput 
                                v-model="address.billing_address.zip_code" 
                                type="text" 
                                :label="$t('pages.tenant_client.postcode')" 
                                :placeholder="$t('pages.tenant_client.postcode')"
                                class="w-full" 
                                :class="{'disableInput': isDisabled}" 
                                :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"  
                                :validation="checkBillingAddressRequired ? [
                                    ['required'],
                                    ['matches', /^[a-zA-Z0-9\\s\\-]+$/]
                                ] : []"
                                :validation-messages="{ matches: 'Only characters supported are alphanumeric, - and spaces' }" 
                                :disabled="!disableAllField || saveAsPrimary" 
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex my-4 justify-between w-full" v-if="disableAllField">
                <Button 
                    :text="$t('actions.back')" 
                    type="outline" 
                    @click="$emit('handleBackSection')" 
                />
                
                <Button
                    :text="$t('pages.tenant_client.save__next')" 
                    type="primary" 
                    :loader="loadingState.submit"
                    @click="handleSubmit(hasErrors)" 
                />
            </div>
        </FormulateForm>
    </div>
</template>

<script>
import Button from "@shared/components/button";
import { createAndUpdateClientAddress, getAddresses, getCountryList } from "../services";
import Loader from "@shared/loader";
import { checkPermission } from "@shared/utils/functions";

export default {
    name: "client-admin-address",
    components: {
        Button,
        Loader,
    },
    props: {
        isClientOnboarded: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            address: {
                primary_address: {
                    line_1: "",
                    line_2: "",
                    city: "",
                    country: "",
                    zip_code: "",
                },
                billing_address: {
                    line_1: "",
                    line_2: "",
                    city: "",
                    country: "",
                    zip_code: "",
                },
            },
            loadingState: {
                address: false,
                country: false,
                submit: false,
            },
            clientId: null,
            countries: [],
            saveAsPrimary: false,
        };
    },
    async mounted() {
        await this.getClientAddress();
        await this.fetchCountry();
    },
    watch: {
        'address.primary_address': {
            deep: true, 
            handler() {
                this.sameAsPrimaryAddress();
            }
        }
    }, 
    computed: {
        disableAllField() {
            if (this.$route.name === "client-details" && !this.isClientOnboarded) return this.checkPermission("client.create");
            else if (this.$route.name === "client-details" && this.isClientOnboarded) return this.checkPermission("client.edit");
            else return false;
        },
        checkBillingAddressRequired() {
            let { line_1, city, zip_code, country, line_2 } = this.address.billing_address;
            const valuesArray = [line_1, city, zip_code, country, line_2];
            return !this.saveAsPrimary && valuesArray.some(x=> (x || '').trim()) && !valuesArray.every(x=> (x || '').trim());
        },
        isDisabled() {
            return (!this.disableAllField || this.saveAsPrimary)
         
        }
    },
    methods: {
        checkPermission,
        async fetchCountry() {
            this.loadingState.country = true;
            this.countries = await getCountryList();
            this.loadingState.country = false;
        },
        async getClientAddress() {
            this.loadingState.address = true;
            this.clientId = this.$route.params.clientId;
            if (this.clientId) {
                try {
                    let response = await getAddresses(this.clientId);
                    this.address = this.convertResponse(response);
                    this.saveAsPrimary = this.address.primary_address.same_as_primary;
                } catch (error) {
                    console.log(error);
                }
            }
            this.loadingState.address = false;
        },
        convertResponse(addresses) {
            if (addresses.length > 0) {
                let primary_address = addresses?.find((el) => el.address_type === "Primary Address");
                let billing_address = addresses?.find((el) => el.address_type === "Billing Address");
                let address = {
                    primary_address,
                    billing_address,
                };
                return address;
            } else {
                return this.address;
            }
        },
        async handleSubmit(hasErrors) {
            this.loadingState.submit = true;
            if (hasErrors) {
                this.$t('pages.tenant_client.please_fill_all_the_required_fields');
            } else {
                let payload = [
                    { ...this.address.primary_address, address_type: "Primary Address", same_as_primary: this.saveAsPrimary },
                    { ...this.address.billing_address, address_type: "Billing Address", same_as_primary: this.saveAsPrimary }
                ];
                try {
                    let response = await createAndUpdateClientAddress(this.clientId, payload);
                    this.$emit("handleNextSection");
                    this.$toast.success(response.message || this.$t('pages.tenant_client.saved')) ;
                } catch (error) {
                    this.$toast.error(error?.response?.data?.detail || this.$t('pages.tenant_client.error_address_update'));
                }
            }
            this.loadingState.submit = false;
        },
        updateCountry(value, type) {
            this.address[type].country = value?.name || value || "";
        },
        sameAsPrimaryAddress(event) {
            // this.saveAsPrimary = true;
            console.log("this.saveAs", this.saveAsPrimary, event);

            if (event || this.saveAsPrimary) {
                this.address.billing_address = { 
                    ...this.address.primary_address, 
                    address_type: "Billing Address", 
                    id: this.address.billing_address.id, 
                    address_id: this.address.billing_address.address_id
                };
            }
        },
    },
};
</script>

<style lang="scss" scoped>
 ::v-deep {
  .disableInput {
    input {
      background: #f9fafb !important;
      cursor: not-allowed;
    }
  }

}
.dropdown {
    min-height: 40px !important;
}
.countryErrorMsg{
    color: #960505;
    font-size: .8em;
    font-weight: 300;
}

</style>
